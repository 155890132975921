var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Drawer",
    {
      attrs: {
        title: "排班选择",
        size: "normal",
        placement: "right",
        closable: true,
        "display-btn": true,
        "is-mask": true,
        visible: _vm.visible,
      },
      on: { close: _vm.onClose },
    },
    [
      _c("div", { staticClass: "popconfirm" }, [
        _c(
          "div",
          { staticClass: "shift-button" },
          _vm._l(_vm.shiftList, function (item, index) {
            return _c("div", { key: index, staticClass: "button-box" }, [
              _c(
                "div",
                {
                  staticClass: "sooner-btn",
                  style: {
                    background: "rgba(" + item.backGroundRgba + ")",
                    color: "rgba(" + item.fontRgba + ")",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onClick(item)
                    },
                  },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "sooner",
                      style: {
                        background: "rgba(" + item.fontRgba + ")",
                        color: "#FFFFFF",
                      },
                      attrs: { title: item.shiftShortName },
                    },
                    [_vm._v(" " + _vm._s(item.shiftShortName) + " ")]
                  ),
                  _c("div", { staticClass: "time" }, [
                    _vm._v(
                      _vm._s(
                        item.shiftRuleName ? item.shiftRuleName : "00:00-23:59"
                      )
                    ),
                  ]),
                ]
              ),
            ])
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "left-button" },
          [
            _c("a-button", { on: { click: _vm.onClick } }, [
              _vm._v("清除排班"),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }